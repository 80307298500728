import React from 'react';
import './App.css';
import Dropzone from './components/Dropzone'
import MultipleTabs from "./components/MultipleTabs";
import ReactNotifications, {store} from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import 'animate.css';

class App extends React.Component {
    state = {
        documents: []
    };

    server = {
        dev: 'http://localhost:3000/v1/api/documents/recognize',
        prod: 'https://app.labsneural.com/v1/api/documents/recognize'
    };

    showNotification = (title, message) => {
        store.addNotification({
            title: `Error: ${title}`,
            message: message,
            type: 'danger',
            container: 'top-right',
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 6000
            }
        });
    };

    callback = (file, languageId) => {
        if (file.xhr) {
            const inputJson = JSON.parse(file.xhr.response);

            if (inputJson.answer.textPdf && inputJson.answer.requestPdf && inputJson.answer.plainText && inputJson.answer.languageId) {
                const textPdfDecoded = Buffer.from(inputJson.answer.textPdf, 'base64');
                const requestPdfDecoded = Buffer.from(inputJson.answer.requestPdf, 'base64');
                const plainTextDecoded = Buffer.from(inputJson.answer.plainText, 'base64');

                // Object.keys(inputJson.answer).forEach(function(key) {
                //     console.log(key);
                // });

                const {documents} = this.state;
                let isAdded = false;
                for (let i = 0; i < documents.length; i++) {
                    if (documents[i].name === file.name && documents[i].languageId === inputJson.answer.languageId) {
                        documents[i] = {
                            name: file.name,
                            languageId: inputJson.answer.languageId,
                            textPdf: textPdfDecoded,
                            requestPdf: requestPdfDecoded,
                            plainText: plainTextDecoded
                        };
                        isAdded = true;
                    }
                }

                if (!isAdded) {
                    documents.push({
                        name: file.name,
                        languageId: inputJson.answer.languageId,
                        textPdf: textPdfDecoded,
                        requestPdf: requestPdfDecoded,
                        plainText: plainTextDecoded
                    });
                }

                this.setState(documents);
            } else {
                let errorText = 'Something went wrong, please try again later';
                if (inputJson.answer.error && inputJson.answer.error.message) {
                    errorText = inputJson.answer.error.message;
                }
                const filename = file.name || '';

                this.showNotification(filename, errorText);

                this.removeCallback(file);
            }
        } else {
            const {documents} = this.state;
            documents.push({name: file.name, languageId: languageId ,textPdf: null, requestPdf: null});
            this.setState(documents);
        }
    };

    removeCallback = (file) => {
        if (file.name) {
            let {documents} = this.state;
            for (let i = documents.length - 1; i >= 0; i--) {
                if (documents[i] && documents[i].name === file.name) {
                    documents = documents.splice(i, 1);
                }
            }
            this.setState(documents);
        }
    };

    render() {
        const {documents} = this.state;

        const server = (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') ? this.server.dev : this.server.prod;

        return (
            <div className="App">
                <div className="App-header">
                    <b>Free online OCR tool</b>
                    <p>Upload any image or any other file
                        format: <b>.jpg</b>, <b>.png</b>, <b>.tiff</b>, <b>.pdf</b>, <b>.doc</b>, <b>.xls</b>. The maximum file size
                        is 10mb.</p>

                    <b>Supported languages:</b>
                    <ul className="inlineList">
                        <li>Arabic OCR</li>
                        <li>Chinese OCR</li>
                        <li>Dutch OCR</li>
                        <li>English OCR</li>
                        <li>French OCR</li>
                        <li>German OCR</li>
                        <li>Greek OCR</li>
                        <li>Hungarian OCR</li>
                        <li>Italian OCR</li>
                        <li>Japanese OCR</li>
                        <li>Malay OCR</li>
                        <li>Polish OCR</li>
                        <li>Portuguese OCR</li>
                        <li>Russian OCR</li>
                        <li>Spanish OCR</li>
                        <li>Thai OCR</li>
                        <li>Turkish OCR</li>
                        <li>Ukrainian OCR</li>
                        <li>Urdu OCR</li>
                    </ul>

                    <b>How to use it</b>
                    <p>Our OCR is free to use and we do not require any registration or email address.</p>
                    <p>Just choose language and upload any image or PDF file.</p>

                    <p>Some document types are really hard to read (e.g. Fapiao OCR and data extraction) so they require
                        some custom modifications e.g. preprocessing to work properly.</p>

                    <p>If you have faced such a case or would like to explore other options as OCR SDK or OCR API,
                        please feel free to reach us at <a href="mailto:info@labsneural.com">info@labsneural.com</a> to
                        discuss details.</p>
                </div>
                <div className="App-content">
                    <ReactNotifications/>
                    <Dropzone callback={this.callback} removeCallback={this.removeCallback} showNotification={this.showNotification} postUrl={server}/>
                    <MultipleTabs documents={documents}/>
                </div>
            </div>
        );
    }
}

export default App;