import React, {Component} from 'react';
import DropzoneComponent from 'react-dropzone-component';
import 'react-dropzone-component/styles/filepicker.css';
import 'dropzone/dist/min/dropzone.min.css';
import '../_extensions.scss';
import '../App.css'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import RadioGroup from '@material-ui/core/RadioGroup';
// import Radio from '@material-ui/core/Radio';
// import FormControl from '@material-ui/core/FormControl';
import {store} from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import 'animate.css';

const OCR_PROVIDER_TESSERACT = 'Tesseract';
// const OCR_PROVIDER_TEXTRACT = 'Amazon Textract';
// const OCR_PROVIDER_GOOGLE = 'Google Vision';
// const OCR_PROVIDER_AZURE = 'Microsoft Azure';

class Dropzone extends Component {
    _isManualDeletion = true;

    constructor(props) {
        super(props);
        const {postUrl} = props;

        const onlyHostSpl = postUrl.split('/');
        const onlyHost = `${onlyHostSpl[0]}/${onlyHostSpl[1]}/${onlyHostSpl[2]}`;

        this.djsConfig = {
            addRemoveLinks: true,
            acceptedFiles: "image/jpeg,image/png,image/gif,image/tiff,image/bmp,application/pdf,application/msword,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            headers: {"Access-Control-Allow-Origin": onlyHost},
            timeout: 600000
        };

        this.componentConfig = {
            iconFiletypes: ['.jpg', '.png', '.tiff', '.bmp', '.pdf', '.doc', '.xls'],
            showFiletypeIcon: true,
            postUrl: postUrl,
            timeout: 600000
        };

        this.dropzone = null;

        this.state = {
            languageId: "eng",
            ocrProvider: OCR_PROVIDER_TESSERACT
        };

        this.sending = this.sending.bind(this);
    }

    success = (file) => {
        const {callback} = this.props;
        this._isManualDeletion = false;
        this.dropzone.removeFile(file);
        callback(file, null);
    };

    error = (file) => {
        if (file.status === 'error') { // receive this event even manually delete file
            const {showNotification} = this.props;
            this._isManualDeletion = true;
            this.dropzone.removeFile(file);
            if (file.xhr.status === 0) {
                showNotification(file.name, 'Too many requests, rate limit exceeded. Please, try again later');
            } else {
                showNotification(file.name, 'Something went wrong, please try again later');
            }
        }
    };

    added = file => {
        const {callback} = this.props;
        callback(file, this.state.languageId);
    };

    removeCallback = file => {
        if (this._isManualDeletion) {
            const {removeCallback} = this.props;
            removeCallback(file);
        } else {
            this._isManualDeletion = true;
        }
    };

    sending = function (file, xhr, data) {
        data.append("languageId", this.state.languageId);
    };

    handleChange = event => {
        this.setState({[event.target.name]: event.target.value});
    };

    handleChangeRadio = (e, key) => {
        if (e.target.value !== OCR_PROVIDER_TESSERACT) {
            store.addNotification({
                title: `${e.target.value}`,
                message: `To enable OCR Provider ${e.target.value}, please contact us: info@labsneural.com`,
                type: 'info',
                container: 'top-right',
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 3000
                }
            });
        }
    };

    render() {
        const config = this.componentConfig;
        const djsConfig = this.djsConfig;

        // For a list of all possible events (there are many), see README.md!
        const eventHandlers = {
            init: dz => this.dropzone = dz,
            success: this.success,
            error: this.error,
            addedfile: this.added,
            sending: this.sending,
            removedfile: this.removeCallback
        };

        return (
            <div className="dropzone-wrapper">
                <div className="row">
                    {/*<div id="inner1">*/}
                        {/*<div className="child-text">*/}
                            {/*OCR provider:*/}
                        {/*</div>*/}
                        {/*<div className="radio-group">*/}
                            {/*<FormControl component="fieldset" required>*/}
                                {/*<RadioGroup row name="ocrProvider" value={this.state.ocrProvider}*/}
                                            {/*onChange={(e) => this.handleChangeRadio(e, 'ocrProvider')}>*/}
                                    {/*<FormControlLabel value={OCR_PROVIDER_TESSERACT} control={<Radio color="primary"/>}*/}
                                                      {/*label={OCR_PROVIDER_TESSERACT}/>*/}
                                    {/*<FormControlLabel value={OCR_PROVIDER_TEXTRACT} control={<Radio color="primary"/>}*/}
                                                      {/*label={OCR_PROVIDER_TEXTRACT}/>*/}
                                    {/*<FormControlLabel value={OCR_PROVIDER_GOOGLE} control={<Radio color="primary"/>}*/}
                                                      {/*label={OCR_PROVIDER_GOOGLE}/>*/}
                                    {/*<FormControlLabel value={OCR_PROVIDER_AZURE} control={<Radio color="primary"/>}*/}
                                                      {/*label={OCR_PROVIDER_AZURE}/>*/}
                                {/*</RadioGroup>*/}
                            {/*</FormControl>*/}
                        {/*</div>*/}
                    {/*</div>*/}
                    <div id="inner1">
                        <div className="child-text">
                            Please choose language:
                        </div>
                        <div className="child-dropdown">
                            <Select value={this.state.languageId} onChange={this.handleChange}
                                    inputProps={{name: 'languageId'}}
                                    id="dropdown-header">
                                <MenuItem value="ara" id="dropdown-item">Arabic</MenuItem>
                                <MenuItem value="chi" id="dropdown-item">Chinese Simplified</MenuItem>
                                <MenuItem value="nld" id="dropdown-item">Dutch</MenuItem>
                                <MenuItem value="eng" id="dropdown-item">English</MenuItem>
                                <MenuItem value="fra" id="dropdown-item">French</MenuItem>
                                <MenuItem value="deu" id="dropdown-item">German</MenuItem>
                                <MenuItem value="ell" id="dropdown-item">Greek</MenuItem>
                                <MenuItem value="hun" id="dropdown-item">Hungarian</MenuItem>
                                <MenuItem value="ita" id="dropdown-item">Italian</MenuItem>
                                <MenuItem value="jpn" id="dropdown-item">Japanese</MenuItem>
                                <MenuItem value="msa" id="dropdown-item">Malay</MenuItem>
                                <MenuItem value="pol" id="dropdown-item">Polish</MenuItem>
                                <MenuItem value="por" id="dropdown-item">Portuguese</MenuItem>
                                <MenuItem value="rus" id="dropdown-item">Russian</MenuItem>
                                <MenuItem value="spa" id="dropdown-item">Spanish</MenuItem>
                                <MenuItem value="tha" id="dropdown-item">Thai</MenuItem>
                                <MenuItem value="tur" id="dropdown-item">Turkish</MenuItem>
                                <MenuItem value="ukr" id="dropdown-item">Ukrainian</MenuItem>
                                <MenuItem value="urd" id="dropdown-item">Urdu</MenuItem>
                            </Select>
                        </div>
                        <div className="child-text">
                            and upload file(s) to OCR
                        </div>
                    </div>
                    <DropzoneComponent
                        config={config}
                        eventHandlers={eventHandlers}
                        djsConfig={djsConfig}
                    />
                </div>
            </div>
        )
    }
}

export default Dropzone;
