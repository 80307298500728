import React, {Component} from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import PDFViewer from './PdfViewer';
import CircularDeterminate from "./CircularDeterminate";
import Button from '@material-ui/core/Button';
import FileSaver from 'file-saver';

function TabContainer({children}) {
    return (
        <Typography component="div" style={{padding: 8 * 3}}>
            {children}
        </Typography>
    );
}

class MultipleTabs extends Component {

    state = {
        activeIndex: 0
    };

    constructor(props) {
        super(props);
        this.handleClickPdf = this.handleClickPdf.bind(this);
        this.handleClickText = this.handleClickText.bind(this);
    }

    handleChange(e, value) {
        this.setState({activeIndex: value});
    }

    handleClickPdf() {
        const {activeIndex} = this.state;
        const {documents} = this.props;
        const blob = new Blob([documents[activeIndex].textPdf], {type: 'application/pdf'});
        let filename = documents[activeIndex].name;
        filename = filename.split('.').slice(0, -1).join('.');
        FileSaver.saveAs(blob, `${filename}-ocr.pdf`);
    }

    handleClickText() {
        const {activeIndex} = this.state;
        const {documents} = this.props;
        const blob = new Blob([documents[activeIndex].plainText], {type: 'text/plain;charset=utf-8'});
        let filename = documents[activeIndex].name;
        filename = filename.split('.').slice(0, -1).join('.');
        FileSaver.saveAs(blob, `${filename}-ocr.txt`);
    }

    render() {
        let {activeIndex} = this.state;
        const {documents} = this.props;

        if (documents && documents.length) {
            activeIndex = Math.min(activeIndex, Math.max(documents.length - 1, 0));

            return (
                <div>
                    <div className="header-recognition">Recognition results:</div>
                    <Tabs
                        value={activeIndex}
                        onChange={(e, value) => this.handleChange(e, value)}
                        indicatorColor="primary"
                        variant="scrollable"
                        scrollButtons="auto">
                        {documents.map((el, index) => React.cloneElement(<Tab/>, {
                            label: `${el.name} - ${el.languageId}`,
                            key: index,
                        }))}
                    </Tabs>
                    <TabContainer>
                        {documents[activeIndex].requestPdf && documents[activeIndex].textPdf ?
                            <div>
                                <div style={{margin: "10px", textAlign: "center"}}>
                                    {/*<Button variant="contained" color="primary" onClick={this.handleClickPdf}>Save PDF</Button>*/}
                                    <Button variant="contained" color="primary" onClick={this.handleClickText}>Save recognized Text</Button>
                                </div>
                                <div id="inner">
                                    <div className="child" id={`requestPdf-${activeIndex}`}>
                                        <PDFViewer containerId={`pdf-request-${activeIndex}`}
                                                   rawData={documents[activeIndex].requestPdf}/>
                                    </div>
                                    <div className="child" id={`textPdf-${activeIndex}`}>
                                        <PDFViewer containerId={`pdf-text-${activeIndex}`}
                                                   rawData={documents[activeIndex].textPdf}/>
                                    </div>
                                </div>
                            </div>
                            :
                            <CircularDeterminate/>
                        }
                    </TabContainer>
                </div>
            );
        } else {
            return <div/>
        }
    }
}

export default MultipleTabs;
